import { Component, EventEmitter, forwardRef, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormBaseControlComponent } from './base';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { BehaviorSubject, delay, Subject, takeUntil, tap } from 'rxjs';
import { AvaIconComponent } from '../../ava-icon/ava-icon.component';
import { getMedicalPlanText } from '@aaa/emember/store-membership';
import { Store } from '@ngrx/store';
import { AppStore } from '@aaa/emember/store-types';
import { AccidentalMedicalCoverageComponent } from '../../accidental-medical-coverage/accidental-medical-coverage';
import { AvaDialogService } from '../../services/ava-dialog.service';

@Component({
  selector: 'ava-form-checkbox-medical',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckBoxMedicalComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, NzCheckboxModule, ReactiveFormsModule, AvaIconComponent],
  standalone: true,
  template: `
    <label
      nz-checkbox
      [nzDisabled]="isDisabled"
      (nzCheckedChange)="changed$.next($event)"
      [formControl]="formControl"
      class="ava-form-control"
    >
      <span class="ava-check-label">
        Accident Medical {{ accidentMedicalPlanPrice ? (accidentMedicalPlanPrice | currency) : '' }} Plan
      </span>
    </label>
    <ng-container *ngIf="medicalPlanText$ | async as medicalPlanText">
      <ava-icon
        iconType="info-circle"
        iconTheme="fill"
        tooltipPlacement="top"
        [tooltipTitle]="medicalPlanText"
        (tooltipClicked)="medicalPlanText && tooltipClicked()"
      />
    </ng-container>
  `,
  styles: [
    `
      @import '../../../../styles/ava-responsive';

      .ava-check-label {
        font-weight: 400;
        font-family: var(--ava-font-family);
      }

      ::ng-deep .ant-checkbox .ant-checkbox-inner {
        border-radius: 0;
        border-color: var(--btn-primary-color);
        border: 1px solid;
      }

      ::ng-deep .ant-checkbox:hover .ant-checkbox-inner {
        border-color: var(--btn-primary-color);
      }

      ::ng-deep .ant-checkbox-checked::after {
        border: 0;
      }

      ::ng-deep .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
        background-color: white;
        border-color: var(--btn-primary-color);
      }
      ::ng-deep .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner::after {
        border: 2px solid var(--btn-primary-color);
        border-top: 0;
        border-left: 0;
      }
    `,
  ],
})
export class FormCheckBoxMedicalComponent extends FormBaseControlComponent implements OnDestroy, OnInit {
  store = inject(Store<AppStore>);
  dialog = inject(AvaDialogService);
  @Input() isDisabled = false;
  @Input() accidentMedicalPlanPrice = 0;
  @Output() changed = new EventEmitter();
  medicalPlanText$ = this.store.select(getMedicalPlanText);
  alive$ = new Subject();
  changed$ = new BehaviorSubject(false);

  tooltipClicked() {
    this.dialog.openComponent({
      nzContent: AccidentalMedicalCoverageComponent,
      nzFooter: null,
    });
  }

  ngOnInit() {
    this.changed$
      .pipe(
        delay(0),
        tap((checked) => this.changed.emit(checked)),
        takeUntil(this.alive$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.alive$.next(null);
    this.alive$.complete();
  }
}
