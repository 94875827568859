import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorService } from '../form/validators/validator.service';
import { MembershipAssociateForm } from './membership-associate-form.model';
import { FormGroupValue } from '../form.utils';

@Injectable({ providedIn: 'root' })
export class MembershipAssociateVm {
  fb = inject(FormBuilder);
  validator = inject(ValidatorService);

  create() {
    const associates = this.fb.array<FormGroup<MembershipAssociateForm>>([]);

    return associates;
  }

  createAssociate(values: Partial<FormGroupValue<MembershipAssociateForm>> = {}) {
    const formGroup = this.fb.group<MembershipAssociateForm>({
      birthday: new FormControl('', {
        nonNullable: true,
        validators: Validators.compose([this.validator.dateFormat()]),
        updateOn: 'blur',
      }),
      email: new FormControl('', {
        nonNullable: true,
        validators: Validators.compose([this.validator.email()]),
        updateOn: 'blur',
      }),
      suffix: new FormControl('', { nonNullable: true }),
      lastName: new FormControl('', {
        nonNullable: true,
        validators: Validators.compose([Validators.required, this.validator.isTextString()]),
        updateOn: 'blur',
      }),
      firstName: new FormControl('', {
        nonNullable: true,
        validators: Validators.compose([Validators.required, this.validator.isTextString()]),
        updateOn: 'blur',
      }),
      removeAssociate: new FormControl(false, { nonNullable: true }),
      middleIntial: new FormControl('', { nonNullable: true }),
      membershipNumber: new FormControl('', { nonNullable: true }),
      accidentMedicalPlan: new FormControl(false, { nonNullable: true }),
    });

    formGroup.patchValue(values);

    return formGroup;
  }
}
