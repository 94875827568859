export const nameSuffix = [
  {
    label: 'Jr',
    value: 'Jr',
  },
  {
    label: 'Sr',
    value: 'Sr',
  },
  {
    label: 'I',
    value: 'I',
  },
  {
    label: 'II',
    value: 'II',
  },
  {
    label: 'III',
    value: 'III',
  },
  {
    label: 'IV',
    value: 'IV',
  },
  {
    label: 'V',
    value: 'V',
  },
  {
    label: 'VI',
    value: 'VI',
  },
  {
    label: 'VII',
    value: 'VII',
  },
];
