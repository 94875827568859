import { inject, Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { ModalOptions } from 'ng-zorro-antd/modal/modal-types';

@Injectable({ providedIn: 'root' })
export class AvaDialogService {
  modal = inject(NzModalService);

  openComponent<T, D = NzSafeAny, R = NzSafeAny>(config: ModalOptions<T, D, R>) {
    return this.modal.create({
      ...config,
      nzClassName: config?.nzClassName ? config?.nzClassName : 'ava-dialog',
      nzWidth: '',
    });
  }
}
